import React, { Component } from 'react';

import { QUESTION_TYPES } from '../../utilities/constants';

import { Row, Input, Checkbox, Radio } from 'antd';

import { database } from '../../firebase';

import Label from './Label';
import Error from './Error';

import _ from 'lodash';

class Question extends Component {
  constructor(props) {
    super(props);

    let answer;

    switch (props.question.type) {
      case QUESTION_TYPES.MULTIPLE_CHOICE:
        answer = [];
      case QUESTION_TYPES.TEXT:
      case QUESTION_TYPES.SINGLE_ANSWER:
      default:
        answer = '';
        break;
    }

    const { quiz, session, submission } = props;

    this.answers = database.ref(`/answers/${quiz}/${session}/${submission}/`);

    this.state = {
      answer,
      invalidOverride: false
    };

    this._renderText = this._renderText.bind(this);
    this._renderMultipleChoice = this._renderMultipleChoice.bind(this);
    this._renderSingleAnswer = this._renderSingleAnswer.bind(this);

    this.changeTextField = this.changeTextField.bind(this);
    this.isError = this.isError.bind(this);

    this.updateAnswer = this.updateAnswer.bind(this);
  }

  componentDidMount() {
    this.answers.once('value', snap => {
      this.setState({
        answer: snap.val()
      });
    });
  }

  _renderMultipleChoice() {
    const { options, answer } = this.props.question;
    const { showAnswer } = this.props;

    const opts = _.map(options, value => {
      return { label: value, value }
    });

    return (
        <Checkbox.Group
          className='checkbox-group'
          onChange={answer => this.updateAnswer(answer)}
        >
          { _.map(opts, (o, key) => 
            <Checkbox 
              className={`checkbox ${(showAnswer && answer.indexOf(o.value) !== -1) ? 'answer' : '' }`}
              key={key}
              value={o.value}
            >
              { o.label }
            </Checkbox>
            )
          }
        </Checkbox.Group>
    );
  }

  _renderText() {
    const { type, placeholder } = this.props.question;
    return (
    
        <Input
          type={type}
          placeholder={placeholder}
          value={this.state.answer}
          onBlur={() => this.updateAnswer()}
          onChange={this.changeTextField}
        />
    );
  }

  changeTextField(answer) {
    this.setState({
      answer: answer.target.value
    });
  }

  _renderSingleAnswer() {
    
    const { options, answer } = this.props.question;
    const { showAnswer } = this.props;
    return (
        
        <Radio.Group>
          {
            _.map(options, (opt, key) => {
              return (
                <Radio 
                  className={`radio ${(showAnswer && key === answer) ? 'answer' : '' }`}
                  value={key} 
                  key={key}
                  onChange={(e) => this.updateAnswer(e.target.value)}
                >
                  {opt}
                </Radio>
              )
            })
          }
        </Radio.Group>
    )
  }

  updateAnswer(answer = null) {
    const id = this.props.question.id;

    // @TODO - Change this, it sucks a lot
    if (!this.props.question.valid) {
      this.setState({
        invalidOverride: true
      });
    }

    // If an answer was provided to this function, send that to firebase
    if (answer === null) {
      answer = this.state.answer;
    }

    let update = {};
    update[id] = answer;
    this.answers.update(update);
  }

  isError() {
    let valid = this.props.question.valid === false ? false : true;
    const { invalidOverride } = this.state;
    return !valid && !invalidOverride;
  }

  render() {

    const { type } = this.props.question;

    let question;
    let questionLabel = this.props.question.question;

    switch (type) {
      case QUESTION_TYPES.TEXT:
        question = this._renderText();
        break;
      case QUESTION_TYPES.MULTIPLE_CHOICE:
        question = this._renderMultipleChoice();
        break;
      case QUESTION_TYPES.SINGLE_ANSWER:
        question = this._renderSingleAnswer();
      default:
        break;
    }

    return (
      <Row className={`question  ${this.isError() ? 'error' : '' }`}>
        <Label text={questionLabel} selectAll={type === QUESTION_TYPES.MULTIPLE_CHOICE} />
        { this.isError() && <Error /> }
        {question}
      </Row>
    );
  }
}

export default Question;