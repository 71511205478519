import React, { Component } from 'react';

// Firebase
import { database, auth } from './firebase';

// Components
import Quiz from './components/Quiz';
import SiteHeader from './components/SiteHeader';
import Admin from './components/Admin';

// Ant Design Components
import { Layout } from 'antd';

// Utilities
import { getSession, getSubmittedQuiz } from './utilities/sessionHelper';

// Constants
import { DEFAULT_QUIZ, SUBMISSION } from './utilities/constants';

// Style
import './App.scss';

class App extends Component {

  constructor(props) {
    super(props);

    this.answers = database.ref('/answers');

    this.defaultQuiz = database.ref('/defaultQuiz');
    
    // console.log(database.ref('quizzes').push().key);

    // Initialize the session
    let session = getSession(this.answers.push().key);

    // By default the submission is set the FIRST
    let submission = SUBMISSION.FIRST;

    // Grab the url param to initialize the quiz
    const params = new URLSearchParams(window.location.search);
    const quiz = params.get('quiz') || DEFAULT_QUIZ;

    // Check if the user has already made a submission for this quiz
    let submittedQuiz = getSubmittedQuiz(quiz);

    if (submittedQuiz) {
      submission = SUBMISSION.SECOND;
    }

    this.state = {
      quiz, session, submission,
      user: null
    };
  }

  componentDidMount() {
    auth.onAuthStateChanged(user => this.setState({ user }));
  }

  render() {
    const { user } = this.state;
    return (
      <Layout>
        <SiteHeader user={user} />
        { !user && 
          <Quiz
            {...this.state}
          />
        }

        { user && <Admin { ...this.state } /> }
      </Layout>
    );
  }
}

export default App;
