import moment from 'moment';

const SESSION_EXPIRY = 3600; // One hour

// Get session hands the key of the current session
export function getSession(session) {
  if (!localStorage.getItem('session')) {
    return newSession(session);
  }

  let currentTime = moment().unix();
  let sessionTime = localStorage.getItem('date');

  if (currentTime - sessionTime > SESSION_EXPIRY) {
    return newSession(session);
  }
  // The current session is still active, pass back the key in localStorage
  return localStorage.getItem('session');
}

// New Session sets the session in localStorage and hands back the key
function newSession(session) {
  localStorage.setItem('session', session);
  localStorage.setItem('date', moment().unix());
  return session;
}

export function storeSubmittedQuiz(quiz, session, submission) {
  localStorage.setItem('submittedQuiz', JSON.stringify({session, quiz, submission}));
}

export function getSubmittedQuiz(qid) {
  let quiz = localStorage.getItem('submittedQuiz');
  if (quiz) {
    quiz = JSON.parse(quiz);
    if (quiz.quiz === qid) {
      return quiz;
    }
  } else {
    return false;
  }
}