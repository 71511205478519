import { DEFAULT_QUIZ, DEFAULT_STATS } from './constants';

import { database } from '../firebase';

import { attachIds } from './questionHelper';
import { scoreQuestion } from './answerHelper';

import _ from 'lodash';

export const isDefaultQuiz = (quiz) => {
  return quiz === DEFAULT_QUIZ;
}

export const getAnswers = async (quiz) => {
  return (await database.ref(`/answers/${quiz}`).once('value')).val();
}

export const getQuizQuestions = async (quiz) => {
  let batch = [];

  // Read the questions
  _.map(quiz.questions, (id) => {
    // Push the call to get the questions into an array
    batch.push(database.ref(`questions/${id}`).once('value'));
  });

  // Get all the questions at once
  let batchReturn = await Promise.all(batch);
  return attachIds(_.map(batchReturn, snapshot => snapshot.val()));
}

export const getStats = (questions, sessions) => {

  let grades = [null, _.cloneDeep(DEFAULT_STATS), _.cloneDeep(DEFAULT_STATS)];

  // Get the total number of required questions
  let total = _.sumBy(questions, (q) => q.required);

  // Iterate through each session record
  _.map(sessions, (session) => {

    // Every session has at most 2 submission
    _.map(session, (s, sessionID) => {
      if (!s) return;
      if (s.length !== total) return;

      console.log(s);
      console.log(sessionID);

      let score = 0;
      
      // Iterate through each answer in the session
      _.map(s, (response, questionID) => {

        // Ignore the timestamp
        if (questionID === 'timestamp') return;

        // Don't check questions that aren't required
        if (!questions[questionID].required) return;
        

        // Score the quiz
        if (questions[questionID].scored) {
          if (scoreQuestion(questions[questionID], response)) {
            score += 1;
          }
        }
      });

      if (!grades[sessionID].barGraph[score]) {
        grades[sessionID].barGraph[score] = 0;
      }

      if (grades[sessionID].lowestScore > score) {
        grades[sessionID].lowestScore = score;
      }

      if (grades[sessionID].highestScore < score) {
        grades[sessionID].highestScore = score;
      }


      grades[sessionID].barGraph[score]++;
      grades[sessionID].averageScore += (score / total);
      grades[sessionID].count++;
      grades[sessionID].total = total;
    });
  });

  console.log(grades);

  grades = _.map(grades, g => {
    if (!g) return;
    return {
      ...g,
      averageScore: ((g.averageScore / g.count) * 100).toFixed(2)
    }
  });

  console.log(grades);

  return grades;
}