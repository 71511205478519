import app from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';

const config = {
  apiKey: "AIzaSyAWtCNqNqPkhgjxEf85prcMqkef0rkBF_o",
  authDomain: "abc-quiz-e8cc8.firebaseapp.com",
  databaseURL: "https://abc-quiz-e8cc8.firebaseio.com",
  projectId: "abc-quiz-e8cc8",
  messagingSenderId: "248244699936",
  appId: "1:248244699936:web:c3d30d990f63eb66"
};

const firebase = app.initializeApp(config);

export default firebase;

export const database = firebase.database();
export const auth = firebase.auth();
