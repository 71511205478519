import React, { Component } from 'react';

// Antd Components
import { Layout, Button, Modal, Input, Row, Col } from 'antd';

// Image
import ABCLogo from '../../assets/ABCWhiteText.png';

// Utilities
import { signIn, signOut } from '../../utilities/authHelper';

const { Header } = Layout;

class SiteHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      signInModal: false,
      signInLoading: false
    };
  }

  signInModal = () => {
    this.setState({ signInModal: true });
  }

  handleOk = async () => {
    const { email, password } = this.state;
    this.setState({
      signInLoading: true
    });

    try {
      await signIn(email, password);
      this.setState({ signInModal: false, signInLoading: false, signInError: false });
    } catch (error) {
      this.setState({
        signInModal: true,
        signInLoading: false,
        signInError: error.code
      });
    }

    
  }

  changeEmail = (e) => {
    this.setState({ email: e.target.value, signInError: false });
  }

  changePassword = (e) => {
    this.setState({ password: e.target.value, signInError: false });
  }

  handleCancel = e => {
    this.setState({ signInModal: false, signInError: false });
  };

  render() {
    const { user } = this.props;
    const { signInLoading, signInError } = this.state;
    return (
      <Header className='theHeader'>
        <div className='logo'>
          <a href='https://atlantic-blockchain.com' target='_blank' rel='noopener noreferrer'>
            <img src={ABCLogo} alt='Atlantic Blockchain Company Logo' />
          </a>
        </div>

        {user && <p className='profile'>{user.email}</p>}
        
        <div className='control'>
          {!user && <Button className='signIn' onClick={this.signInModal}>Sign In</Button>}  
          {user && <Button className='signOut' onClick={signOut}>Sign Out</Button>}
        </div>

        <Modal
          title='Sign In'
          visible={this.state.signInModal}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          footer={null}
          className='signInForm'
        >
          <Input placeholder='Your Email' onChange={this.changeEmail} value={this.state.email} />
          <Input.Password placeholder='Password' onChange={this.changePassword} value={this.state.password} />
          {signInError && <p className='error'>{signInError}</p>}
          <Button loading={signInLoading} type='primary' onClick={this.handleOk}>Sign In</Button>
        </Modal>
      </Header>
    );
  }
}

export default SiteHeader