import React, { Component } from 'react';

// Firebase
import { database } from '../../firebase';

// Antd Components
import { Table, Button, Icon, Row, Col } from 'antd';

// Utilities
import { getAnswers } from '../../utilities/quizHelper';

// Components
import Answers from './Answers';

// helper
import _ from 'lodash';

// The columns that make up the Admin Quiz Table
const quizColumns = [
  {
    title: 'Quiz Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Quiz Key',
    dataIndex: 'quizKey',
    key: 'quizKey'
  },
  {
    title: 'Inspect',
    dataIndex: 'inspect',
    key: 'inspect'
  }
];

// Admin is essentially two pages
//  1. The list of quizzes where any individual quiz can be viewed
//  2. The data pertaining to the single quiz that was selected
class Admin extends Component {

  constructor(props) {
    super(props);

    this.fetchAnswers = this.fetchAnswers.bind(this);
    this.gotoQuizTable = this.gotoQuizTable.bind(this);

    this.state = {
      quizzes: null,
      quiz: null,
      answers: null
    };
  }

  componentDidMount = () => {
    // Get the quizzes
    database.ref('quizzes').on('value', snap => {
      const quizzes = snap.val();

      // Organize the quiz data for antd Table
      const quizData = _.map(quizzes, (quiz, key) => {
        return {
          key,
          name: quiz.name,
          graded: quiz.graded === undefined ? true : quiz.graded,
          quizKey: key,
          inspect: <Button onClick={() => this.fetchAnswers(quiz, key)}>Inspect</Button>
        };
      });

      this.setState({ quizzes: quizData });
    });
  }

  // Essentially a back button
  gotoQuizTable = () => {
    this.setState({
      answers: null,
      quiz: null
    });
  }

  // This is the function that selects the information pertaining to a single quiz
  fetchAnswers = async (quiz, key) => {
    const answers = await getAnswers(key);

    this.setState({
      answers,
      quiz
    });
  }

  render() {
    const { quizzes, answers, quiz } = this.state;
    return (
      <Row className='admin'>
        <Col span={24}>
          { !answers && <Table className='quizTable' columns={quizColumns} dataSource={quizzes} /> }
          { answers && <Icon className='backButton' type='rollback' onClick={this.gotoQuizTable} /> }
          { answers && <Answers answers={answers} quiz={quiz} /> }
        </Col>
      </Row>
    );
  }
}

export default Admin;