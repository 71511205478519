import React, { Component } from 'react';

import './Score.scss';

import { SCORES } from '../../utilities/constants';

const { FAIL, GOOD } = SCORES;

class Score extends Component {

  render() {
  
    let grade;

    const { score, graded } = this.props;

    if (score <= FAIL) {
      grade = 'fail';
    } else if (score > FAIL && score <= GOOD) {
      grade = 'good';
    } else {
      grade = 'perfect';
    }

    return (
      <div className='score'>
        { graded && <div>
          <span className='label'>You Scored</span>
          <span className={`theScore ${grade}`}>{(score * 100).toFixed(2)} %</span>
        </div>
        }
        {
          !graded && <div>
            <span className='thankYou'>Thank you for your participation</span>
          </div>
        }
      </div>
    );
  }
};

export default Score;