// Quesiton Types
const TEXT = 'TEXT';
const MULTIPLE_CHOICE = 'MULTIPLE_CHOICE';
const SINGLE_ANSWER = 'SINGLE_ANSWER';

// submissions
const FIRST = 1;
const SECOND = 2;

// The default Quiz
export const DEFAULT_QUIZ = '-Lk5ZuLG5af984MXYq-a';

export const QUESTION_TYPES = {
  TEXT,
  MULTIPLE_CHOICE,
  SINGLE_ANSWER
};

export const COLORS = {
  PRIMARY: '#1DA57A',
  SECONDARY: '#2E67FB',
  WARNING: '#FFCC11',
  ERROR: '#D8000C',
  BLACK: '#000000'
};

export const DEFAULT_STATS = {
  averageScore: 0,
  total: 0,
  count: 0,
  lowestScore: 1000,
  highestScore: 0,
  barGraph: {}
};

export const SCORES = {
  FAIL: .5,
  GOOD: .9
};

export const SUBMISSION = {
  FIRST, SECOND
};


