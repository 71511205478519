import React, { Component } from 'react';

import { BarChart, Bar, XAxis, YAxis, Label, Legend, Tooltip, ResponsiveContainer } from 'recharts';

// Utilities
import { getStats, getQuizQuestions } from '../../../utilities/quizHelper';

// Antd Components
import { Row, Col, Statistic, Card, Icon } from 'antd';

// Constants
import { COLORS, SUBMISSION, SCORES } from '../../../utilities/constants';

const { FIRST, SECOND } = SUBMISSION;
const { PRIMARY, SECONDARY, WARNING, ERROR, BLACK } = COLORS;
const { FAIL, GOOD } = SCORES;

// Show a different Icon, depending on the score
const getScoreIcon = (score) => {
  score = score / 100;
  if (score <= FAIL) return 'frown';
  if (score > FAIL && score < GOOD) return 'meh';
  return 'smile';
};

// Show a different color, depending on the score
const getScoreColor = (score) => {
  score = score / 100;
  if (score <= FAIL) return ERROR;
  if (score > FAIL && score <= GOOD) return WARNING;
  return PRIMARY;
};

// Answers is essentially a Bar graph, and meta data about the quiz.
class Answers extends Component {

  constructor(props) {
    super(props);

    // Stats drive the whole component
    this.state = {
      stats: null
    };

    this.renderBarChart = this.renderBarChart.bind(this);
    this.renderAverages = this.renderAverages.bind(this);
    this.renderMeta = this.renderMeta.bind(this);
  }

  // Loads the stats
  componentWillMount = async () => {
    const { quiz, answers } = this.props;

    const questions = await getQuizQuestions(quiz);

    let stats = getStats(questions, answers);

    this.setState({
      stats
    });
  }

  // The bar chart is the first row of data
  renderBarChart() {
    const { stats } = this.state;
    let data = [];

    // Organize the stats into a format the BarChart can understand
    // For every possible score (0 to total), possibly push a bar into the chart
    for (let i = 0; i <= stats[1].total; i++) {
      // Initialize the bar
      let bar = {
        score: i,
        before: 0,
        after: 0
      };

      // The first submission
      if (stats[FIRST].barGraph[i]) {
        bar.before = stats[FIRST].barGraph[i];
      }

      // The second submssion
      if (stats[SECOND].barGraph[i]) {
        bar.after = stats[SECOND].barGraph[i];
      }

      // If there was a result in either submission, push a bar
      if (bar.before !== 0 || bar.after !== 0) {
        data.push(bar);
      }
    }

    return (
      <Row className='chart'>
        <Col span={24}>
          <Card className='barChartCard'>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart data={data}>
                <XAxis dataKey='score'>
                  <Label value='Score' offset={0} position='insideBottom' />
                </XAxis>
                <YAxis />
                <Tooltip />
                <Legend verticalAlign='top' height={36} />
                <Bar dataKey='before' fill={PRIMARY} />
                <Bar dataKey='after' fill={SECONDARY} />
              </BarChart>
            </ResponsiveContainer>
          </Card>
        </Col>
      </Row>
    )
  }

  // Averages is the first row of meta data
  renderAverages() {
    const { stats } = this.state;

    let difference = stats[SECOND].averageScore - stats[FIRST].averageScore;

    const firstAverageScore = stats[FIRST].averageScore;
    const secondAverageScore = stats[SECOND].averageScore;

    return (
      <Row>
        <Col span={6}>
          <Card>
            <Statistic
              title='Average Before'
              value={firstAverageScore}
              precision={2}
              valueStyle={{ color: getScoreColor(firstAverageScore) }}
              prefix={<Icon type={getScoreIcon(firstAverageScore)} />}
              suffix='%'
            />
          </Card>
        </Col>
        <Col span={6}>
          <Card>
            <Statistic
              title='Average After'
              value={secondAverageScore}
              precision={2}
              valueStyle={{ color: getScoreColor(secondAverageScore) }}
              prefix={<Icon type={getScoreIcon(secondAverageScore)} />}
              suffix='%'
            />
          </Card>
        </Col>
        <Col span={12}>
          <Card>
            <Statistic
              title='Difference'
              value={difference}
              precision={2}
              valueStyle={{ color: difference > 0 ? PRIMARY : ERROR }}
              prefix={<Icon type={difference > 0 ? 'arrow-up' : 'arrow-down'} />}
              suffix='%'
            />
          </Card>
        </Col>
      </Row>
    )
  }

  // Meta is the second row of Metadata
  renderMeta() {
    const { stats } = this.state;
    return (
      <Row>
        <Col span={6}>
          <Card>
            <Statistic
              title='Number of Questions'
              value={stats[FIRST].total}
              precision={0}
              valueStyle={{ color: BLACK }}
            />
          </Card>
        </Col>

        <Col span={9}>
          <Card>
            <Statistic
              title='Number of Submissions Before'
              value={stats[FIRST].count}
              precision={0}
              valueStyle={{ color: BLACK }}
            />
          </Card>
        </Col>

        <Col span={9}>
          <Card>
            <Statistic
              title='Number of Submissions After'
              value={stats[SECOND].count}
              precision={0}
              valueStyle={{ color: BLACK }}
            />
          </Card>
        </Col>
      </Row>
    )
  }

  render() {
    const { stats } = this.state;
    return (
      <Row>
        <Col span={24}>
          <h1>{ this.props.quiz.name }</h1>
        </Col>
        <Col span={24}>
          {stats &&
            <div>
              {this.renderBarChart()}
              {this.renderAverages()}
              {this.renderMeta()}
            </div>
          }
        </Col>
      </Row>
    );
  }
}

export default Answers;