// Firebase
import { database } from '../firebase';

// Constants
import { SUBMISSION, QUESTION_TYPES } from './constants';

// Optimizer
import _ from 'lodash';

const answers = database.ref('/answers/');

export async function submitQuiz(quiz, session, questions, submission = SUBMISSION.FIRST) {

  // Assume the quiz is valid
  let valid = true;

  // We are going to need to update the question set
  let questionsUpdate = _.clone(questions);

  // Initialize the score
  let score = 0;
  let total = 0;

  // Get the users answers
  let response = await answers.child(`/${quiz}/${session}/${submission}/`).once('value');

  // Pull the users answers from the response
  let userAnswers = response.val();

  // Iterate through the questions, to check the answers
  _.map(questions, (question, qid) => {

    // Don't check questions that aren't required
    if (!question.required) return;

    // If the user didn't answer a question, then the quiz is not valid
    if (!userAnswers || (userAnswers[qid] === null || userAnswers[qid] === undefined)) {
      valid = false;
      questionsUpdate[qid].valid = false;
      return;
    }

    // Score the quiz
    if (question.scored) {
      total += 1;
      if (scoreQuestion(question, userAnswers[qid])) {
        score += 1;
      }
    }
  });

  return {
    questionsUpdate,
    valid,
    score: score / total
  };
}

/*
 * scoreQuestion
 * @param - question - The question to mark
 * @param - answers  - The answer that the user gave
 */ 
export function scoreQuestion(question, answer) {
  if (answer === undefined || answer === null) return false;
  let correct = true;
  switch (question.type) {
    case QUESTION_TYPES.MULTIPLE_CHOICE:
      if (answer.length > question.answer.length) {
        correct = false;
      } else {
        _.map(question.answer, a => {
          if (answer.indexOf(a) === -1) {
            correct = false;
          }
        });
      }
      break;
    case QUESTION_TYPES.SINGLE_ANSWER:
      correct = answer === question.answer;
      break;
    default:
      break;
  }
  return correct;
}