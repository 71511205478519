import React, { Component } from 'react';

import { database } from '../../firebase';

// Components
import Loading from '../Loading';
import Question from '../Question';
import Score from '../Score';

// Utilities
import { submitQuiz } from '../../utilities/answerHelper';
import { isDefaultQuiz, getQuizQuestions } from '../../utilities/quizHelper';

// antd
import { Row, Col, Button, Modal } from 'antd';

import _ from 'lodash';
import { storeSubmittedQuiz } from '../../utilities/sessionHelper';

const SMALL_COL = { span: 24, offset: 0 };
const LARGE_COL = { span: 10, offset: 7 };

class Quiz extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questions: [],
      submitted: false,
      score: '',
      loading: true,
      answersModal: false,
      showAnswer: false,
      subscriptionEmail: '',
      errorMessage: ''
    };

    const { quiz, session, submission } = props;

    this.answers = database.ref(`/answers/${quiz}/${session}/${submission}/`);
    this.quiz = database.ref(`/quizzes/${quiz}`);
    this.questions = database.ref(`/questions/`);

    this.submitQuiz = this.submitQuiz.bind(this);
    this.showAnswers = this.showAnswers.bind(this);

    this.subscriptionEmailChange = this.subscriptionEmailChange.bind(this);
  }


  componentDidMount() {
    // Get the quiz
    this.quiz.once('value', async (snap) => {
      let quiz = snap.val();

      // Read the questions
      let questions = await getQuizQuestions(quiz);

      console.log('HERE');
      console.log(quiz);

      this.setState({
        questions,
        name: quiz.name,
        graded: quiz.graded === undefined ? true : quiz.graded,
        loading: false
      });
    });
  }

  showAnswers() {
    this.setState({
      answersModal: true
    });
  }

  handleOk = async (onlyAnswers) => {
    
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    
    if (onlyAnswers === true) {
      this.setState({
        showAnswer: true,
        answersModal: false
      });
    } else {
      if (this.state.subscriptionEmail === '') {
        this.setState({
          errorMessage: 'Please enter email'
        });
      } else if (emailRegex.test(this.state.subscriptionEmail)) {
        await database.ref('/subscriptions').push(this.state.subscriptionEmail);
        this.setState({
          showAnswer: true,
          answersModal: false,
          errorMessage: ''
        });
      } else {
        this.setState({
          errorMessage: 'Please enter valid email'
        });
      }
    }
  };

  handleCancel = e => {
    this.setState({
      showAnswer: false,
      answersModal: false,
      errorMessage: ''
    });
  };

  async submitQuiz() {
    // Variables required to pass to the grading function
    const { quiz, session, submission } = this.props;
    const { questions } = this.state;

    // Submit the quiz for grading
    let results = await submitQuiz(quiz, session, questions, submission);

    // Pull the score, and updates from results
    const { valid, questionsUpdate, score } = results;

    // Build the correct state updates
    let update = {};

    if (valid) {
      // Store the fact that the quiz has been submitted in localStorage
      storeSubmittedQuiz(quiz, session, submission);
      update = {
        questions: questionsUpdate,
        submitted: true,
        score
      };
    } else {
      update = {
        questionsUpdate,
        submitted: false
      };
    }
    // Apply a timestamp on every submission
    this.answers.update({ timestamp: new Date() });

    // Apply the state update
    this.setState(update);
  }

  subscriptionEmailChange(e) {
    this.setState({
      subscriptionEmail: e.target.value
    });
  }

  render() {
    let { questions, submitted, score, name, loading, showAnswer, graded } = this.state;
    let { quiz, session, submission } = this.props;

    console.log('HERE - 2');
    console.log(graded);
    questions = _.toArray(questions);
    return (
      <Row className='quiz'>
        {
          loading ? <Loading /> :
            <Col
              xs={SMALL_COL}
              sm={SMALL_COL}
              md={LARGE_COL}
              lg={LARGE_COL}
            >
              <h2 className='quizTitle'>{name}</h2>

              {isDefaultQuiz(quiz) &&
                <div className='info'>
                  <hr />
                  <p>Time: <span className='time'>5 Minutes</span></p>
                  <p>Find out how much you know about Blockchain!</p>
                  <hr />
                </div>
              }

              {!submitted &&
                <div>
                  {
                    !_.isEmpty(questions) && _.map(this.state.questions, (q, i) => {
                      return (
                        <Question
                          key={i}
                          question={q}
                          quiz={quiz}
                          session={session}
                          submission={submission}
                          showAnswer={showAnswer}
                        />);
                    })
                  }

                  <Button
                    type='primary'
                    onClick={() => this.submitQuiz()}
                    className='quizButton'
                  >
                    Submit
                </Button>

                  <Button
                    type='primary'
                    className='quizButton showAnswerButton'
                    onClick={() => this.showAnswers()}
                  >Show me the Answers</Button>
                </div>
              }
              {submitted && <Score graded={graded} score={score} />}
            </Col>
        }
        <Modal
          title='Do you want to know the answers?'
          visible={this.state.answersModal}
          onCancel={this.handleCancel}
          footer={null}
        >
          <div className='subscribe-modal'>
            <h2>Subscribe to our newsletter!</h2>
            <div className='email'>
              <input placeholder='Email Address' type='email' onChange={this.subscriptionEmailChange} value={this.state.subscriptionEmail} name='EMAIL' className='required email' id='mce-EMAIL' />
              {this.state.errorMessage &&
                <p className='error'>
                  {this.state.errorMessage}
              </p>
              }
            </div>
            <div>
              <Button type='primary' className='sub' onClick={() => this.handleOk(false)}>Subscribe, and show me the answers</Button>
              <Button type='primary' className='no-sub' onClick={() => this.handleOk(true)}>Just show me the answers</Button>
            </div>
          </div>
        </Modal>
      </Row>
    );
  }
}

export default Quiz;